<template>
<div>
  <div class="ui-slate">
    <span v-if="!event.state">
      The {{$app.eventName}} has not started yet
    </span>
    <span v-else-if="event.state == 'starting'">
      The {{$app.eventName}} is about to start...
    </span>
    <span v-else-if="event.state == 'paused'">
      The {{$app.eventName}} is about to resume
    </span>
    <span v-else-if="event.state == 'complete'">
      The {{$app.eventName}} is over
    </span>
  </div>
</div>
</template>

<script>
export default {
  data() {
    return {
      event: {state:'complete'}
    }
  }
}
</script>

<style>
.ui-slate {
  text-align: center;
  padding-top: 25%;
  padding-left: 20%;
  padding-right: 20%;
  font-size: 5vw;
  height: 500px;
  width: 100vw;
  background-color: rgb(215, 227, 250);
}
</style>